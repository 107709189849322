import {Routes, Route} from 'react-router-dom';
import SurveyForm from './SurveyForm';
import TypeForm from './TypeForm';
import TypeFormMobile from './TypeformMobile';
import Map2 from './Map2';
import MapPlot from './MapPlot';


export default function AppRouter(){

  return (
    <Routes>
      <Route path='' element={<SurveyForm />} />
      <Route path='/typeform_old' element={<TypeForm />} />
      <Route path='/typeform-mobile' element={<TypeFormMobile />} />
      <Route path='/typeform' element={<Map2 />} />
      <Route path='/map' element={<MapPlot />} />
    </Routes>
  )
}

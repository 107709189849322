import { useState } from 'react';
import MapPlot from './MapPlot';
import { getTypeFormMapResult } from './API';
import { Widget } from '@typeform/embed-react'

function Hr(){
  return (
    <div className='max-w-lg'>
      <hr className="text-left w-52 h-1 mx-auto my-2 font-thin border-t-1 border-t-gray-400 rounded md:my-6"/>
    </div>
  )
}

function timeout(delay) {
  return new Promise( res => setTimeout(res, delay) );
}

export default function TypeFormMobile(){
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingMap, setIsLoadingMap] = useState(false);
  const [map_params, setMapParams] = useState();

  async function handleTypeFormSubmit(responseId){
    await timeout(1500);
    const Xresponse = await getTypeFormMapResult(responseId);
    const response = {"success":true,"data":{"lat_lons":{"survey_id":"matldmrcw0tiwngvhmatldmyr1irofzt","home":{"lat":45.5653,"lon":-122.6448},"providers":[{"care_type":"Clinical Trial","provider":"Study of Sparsentan Treatment in Pediatrics With Proteinuric Glomerular Diseases","address":"Seattle, Washington","lat":47.610902,"lon":-122.336422},{"care_type":"Glomerular Specialist","provider":"Gia Oh, MD","address":"Legacy Health, 501 North Graham Street, Portland, OR 97227","lat":45.543668,"lon":-122.6711373},{"care_type":"IGAN Specialist","provider":"Vijay Mudunuri, MD","address":"300 Torburry Way, Folsom, CA 95630","lat":38.648866,"lon":-121.105247},{"care_type":"Kidney Dietitian","provider":"Kara Odiaga, Rd, CSR LD","address":"703 Ne Hancock St, Portland, OR, 97212","lat":45.536786,"lon":-122.658289},{"care_type":"Nephrologist","provider":"Sonia Bynum, MD","address":"1547 Ne 40th Ave Ste B, Portland, OR, 97232","lat":45.534663,"lon":-122.622558}]}}};
    
    
    if (response.success) {
      setMapParams(response.data.lat_lons);
    } else {
      setIsError(true);
    }
    setIsLoadingMap(false);
  }

  return (
    // bg-white here prevents a parent site hosting the iFrame from overriding the margin color.
    <div className='!bg-white'>
      {isLoading ?
        <div 
          className="
            w-full
            mx-3
            p-10
            mb-6
            md:mb-0
            text-gray-700
            text-2xl
            font-bold
            text-left"
        >
          Loading Form ...
        </div>
        :
        <>
          {!map_params && !isError &&
            // Allegedly `data-tf-inline-on-mobile` is supposed to remove the "Welcome page" that is
            // forced upon all users on mobile. Ref: https://community.typeform.com/typeform-developers-44/welcome-screen-on-mobile-embedded-form-477
            <div className={`${"m-8"} content-center`}>
              <Widget
                id="fyVacErv"
                style={{ width: '100%', height: `600px`}}
                className="my-form"
                inlineOnMobile // Prevents welcome page from loading on mobile.
                onSubmit={( e ) => {
                  // execute API call for e.responseId to get map results
                  // console.log(`Form ${formId} submitted, response id: ${responseId}`)
                  setIsLoadingMap(true);
                  handleTypeFormSubmit(e.responseId);
                }
              }/>
            </div>
          }

          {/* MAP PLOT */}
          {map_params && !isError &&
            <div className="flex flex-col justify-center items-center !bg-white">
              <div className="my-2 text-2xl text-gray-600 font-bold">Providers Near You</div>
              <div className="mb-1 text-sm text-gray-600 font-semibold">Tap or hover over a location for details</div>
              <Hr />
              <div className="-pl-20" style={{paddingLeft: "-25px", position: "-50px"}}>
                <MapPlot map_params={map_params} width={700} height={800} />
              </div>
              <Hr />
              <div className="-pl-20 text-left text-sm ml-16" style={{width: "700px"}}>
                The following define the care types shown above; they are the locations within 500 miles that are nearest to your address.
                <br/><br/>
                <span className="font-bold">Nephrologist</span>: A nephrologist who likely does not specialize in rare kidney diseases
                <br/>
                <span className="font-bold">Glomerular Specialist</span>: A physician who specializes in a rare glomerular disease, but not necessarily IGAN
                <br/>
                <span className="font-bold">CKD Support Group</span>: An in-person support group for chronic kidney disease patients; online support groups not shown here
                <br/>
                <span className="font-bold">IGAN Specialist</span>: A nephrologist who is known to specialize in IGAN, as verified by the IGA Nephropathy Foundation
                <br/>
                <span className="font-bold">Clinical Trial</span>: Location of an in-person IGAN clinical trial
                <br/><br/>
                Please note that if you want to work with one of these locations, you should verify the details yourself first.
              </div>
            </div>
          }

          {/* MAP LOADING */}
          {isLoadingMap && !isError &&
            <div>Loading map ...</div>
          }

          {/* ERROR PAGE */}
          {isError &&
            <div>
              <div className='mt-56 mx-96 text-lg font-bold'>
                We encountered an error submitting your information. It may be because you entered an invalid or non-US zip code. If you have questions, comments, or requests please email info@tealeafhealth.com
              </div>
            </div>
          }
        </>
      }
      <br/>
    </div>
  )
}

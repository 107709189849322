import { useState, useEffect } from "react";
import { Map, Marker, Overlay, ZoomControl } from "pigeon-maps";
import { HomeIcon } from "@heroicons/react/24/solid";
import turfBbox from "@turf/bbox";
import {
  featureCollection as turfFeatureCollection,
  point as turfPoint,
} from "@turf/helpers";
import geoViewport from "@mapbox/geo-viewport";

const style = {
  position: "absolute",
  bottom: 0,
  right: 217.1,
  height: 40,
  fontSize: "11px",
  padding: "2px 0px 0px 5px",
  background: "rgba(255, 255, 255, 0.7)",
  fontFamily: "'Helvetica Neue', Helvetica, Arial, sans-serif",
  color: "#333",
};

const linkStyle = {
  color: "#0078A8",
  textDecoration: "none",
};

function centerZoomFromLocations(map_data, width, height) {
  const locations = map_data.providers;

  const points = locations.map((location) =>
    turfPoint([location.lon, location.lat])
  );
  points.push(turfPoint([map_data.home.lon, map_data.home.lat]));
  const features = turfFeatureCollection(points);
  const bounds = turfBbox(features);

  const { center, zoom } = geoViewport.viewport(bounds, [width, height]);

  return [[center[1], center[0]], Math.min(zoom, 13)];
  // return {
  //   center: [center[1], center[0]],
  //   zoom: Math.min(zoom, 13)
  // }
}

export default function MapPlot({ map_params, width = 500, height = 500 }) {
  const [isVisible, setIsVisible] = useState(
    map_params.providers.map((d) => true)
  );
  let mapData = map_params;

  function handleChangeVisible(i, visibleBool) {
    const visibleUpdate = [...isVisible];
    // Prevents two overlays from appearing at once due to markers touching
    // and mouse sliding over the next without actually leaving the first.
    for (var j = 0; j < visibleUpdate.length; j++) {
      if (i !== j) visibleUpdate[j] = true;
    }
    visibleUpdate[i] = visibleBool;
    setIsVisible(visibleUpdate);
  }

  function handleClick(i) {
    let rows = document.getElementsByClassName("providercell");
    const activeStyle = ["bg-yellow-100", "font-bold", "underline"];
    for (let i = 0; i < rows.length; i++) {
      activeStyle.forEach((style) => {
        rows[i].classList.remove(style);
      });
    }
    const row = document.getElementById(`provider-${i}`);
    row.scrollIntoView({
      behavior: "smooth",
      block: "center",
      inline: "nearest",
    });
    activeStyle.forEach((style) => {
      row.classList.add(style);
    });
  }

  // Force all annotations overlays to hide.
  useEffect(() => {
    const visibleUpdate = [...isVisible];

    for (var i = 0; i < visibleUpdate.length; i++) {
      visibleUpdate[i] = true;
    }
    setIsVisible(visibleUpdate);
  }, []);

  const [center, setCenter] = useState(null);

  const [zoom, setZoom] = useState(null);

  useEffect(() => {
    const [newCenter, newZoom] = centerZoomFromLocations(
      map_params,
      width,
      height
    );
    setCenter(newCenter);
    setZoom(newZoom - 1);
    const visibleUpdate = [...isVisible];

    for (var i = 0; i < visibleUpdate.length; i++) {
      visibleUpdate[i] = false;
    }
  }, [map_params, width, height]);

  return (
    <div className="text-3xl font-bold text-left text-slate-800">
      <div className="">
        <Map
          height={height}
          className="max-w-md  md:max-w-2xl"
          defaultCenter={center}
          defaultZoom={zoom}
          center={center}
          zoom={zoom}
          onBoundsChanged={({ center, zoom }) => {
            setCenter(center);
            setZoom(zoom);
          }}
        >
          <ZoomControl />
          <Marker
            className="text-left"
            width={1}
            color="blue"
            anchor={[mapData.home.lat, mapData.home.lon]}
          />
          <Overlay
            anchor={[mapData.home.lat, mapData.home.lon]}
            offset={[25, 50]}
            width="50"
            height="50"
          >
            <HomeIcon width="50" height="50" color="blue" />
          </Overlay>

          {mapData.providers.map((mapLocation, i) => (
            <Marker
              key={`marker-${i}`}
              className="text-left"
              width={50}
              color="red"
              anchor={[mapLocation.lat, mapLocation.lon]}
              onClick={() => handleClick(i)}
              onMouseOver={() => handleChangeVisible(i, false)}
              onMouseOut={() => handleChangeVisible(i, true)}
            />
          ))}
          {mapData.providers.map((mapLocation, i) => (
            <Overlay
              key={`overlay-${i}`}
              anchor={[mapLocation.lat, mapLocation.lon]}
              offset={[-10, 120]}
              width="100"
              height="100"
            >
              {!isVisible[i] && (
                <div className="px-2 py-1 bg-white text-sm rounded-lg drop-shadow-lg w-56">
                  <span className="text-slate-800 font-extrabold">
                    {mapLocation.provider}
                  </span>
                  <br />
                  <span className="text-slate-500">
                    {mapLocation.care_type}
                  </span>
                  <br />
                  <span className="text-slate-500">{mapLocation.address}</span>
                  <br />
                </div>
              )}
            </Overlay>
          ))}

          <div key="attr" className="pigeon-attribution" style={style}>
            <span>
              <a
                href="https://tealeafhealth.com/"
                style={linkStyle}
                target="_blank"
                rel="noreferrer noopener"
              >
                Tea Leaf Health
              </a>
              {` | `}
            </span>
          </div>
        </Map>
      </div>
    </div>
  );
}

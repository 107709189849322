import { useState } from 'react';
// import { useNavigate } from 'react-router-dom';
// import { MultiSelect } from "react-multi-select-component";
import MapPlot from './MapPlot';
// import Select from 'react-select';
import { postSurvey, updateSurvey } from './API';
import { states, isValidEmail, isValidInt } from './utils';
import {isMobile} from 'react-device-detect';

const formFields = {
  relationship_to_pkd: null,
  relationship_to_pkd_egfr: null,
  street_address: null,
  city: null,
  state: null,
  zip_code: null,
  age: null,
  gender: null,
  race: null,
  is_hispanic: null,
  education: null,
  insurance_types: [],
  insurance_types_other: null,
  frequented_location_1: null,
  frequented_location_2: null,
  pkd_healthcare_issue_1: null,
  pkd_healthcare_issue_2: null,
  pkd_journey_issues: [],
  pkd_journey_issues_other: null,
  care_location_interests: [],
  care_location_interests_other: null,
  additional_care_location_type_1: null,
  additional_care_location_type_2: null,
  is_relevant: null,
  email: null
}

const formFieldErrors1 = {
  relationship_to_pkd: true,
  relationship_to_pkd_egfr: false,
  street_address: true,
  city: true,
  state: true,
  zip_code: true,
  age: true,
  gender: true,
  race: true,
  is_hispanic: true,
  education: true,
  insurance_types: true,
  insurance_types_other: false,
  frequented_location_1: true,
  frequented_location_2: true,
  pkd_healthcare_issue_1: true,
  pkd_healthcare_issue_2: true,
  pkd_journey_issues: true,
  pkd_journey_issues_other: false
}

const formFieldErrors2 = {
  care_location_interests: false,
  care_location_interests_other: false,
  additional_care_location_type_1: false,
  additional_care_location_type_2: false,
  is_relevant: false,
  email: false
}

const accessOptions = [
  {label: 'Nephrologist specializing in my condition', value: 'neph'},
  {label: 'CKD dietitian', value: 'ckd dietitian'},
  {label: 'Clinical trial', value: 'clinical trial'},
  {label: 'CKD support groups', value: 'ckd support'}
]

const conditionTypeOptions = [
  {label: 'Polycystic Kidney Disease (PKD)', value: 'polycystic kidney disease'},
  {label: 'Alport Syndrome', value: 'alport syndrome'},
  {label: 'Any rare Chronic Kidney Disease (CKD)', value: 'any rare chronic kidney disease'}
]

const educationOptions = [
  {label: "Less than a high school diploma", value: "less than a high school diploma"},
  {label: "High school diploma or GED", value: "high school diploma or ged"},
  {label: "Two or four year college degree", value: "two or four year college degree"},
  {label: "Master’s degree", value: "masters degree"},
  {label: "Professional degree (e.g., MD, JD, doctorate)", value: "professional degree"}
]

const genderOptions = [
  {label: "Male", value: "male"},
  {label: "Female", value: "female"},
  {label: "Non-binary", value: "non-binary"},
  {label: "Transgender male", value: "transgender male"},
  {label: "Transgender female", value: "transgender female"},
  {label: "Prefer not to answer", value: "prefer not to answer"},
]

const raceOptions = [
  {label: "American Indian or Alaska Native", value: "american indian or alaska native"},
  {label: "Asian or Asian American", value: "asian or asian american"},
  {label: "Black or African American", value: "black or african american"},
  {label: "Native Hawaiian or Other Pacific Islander", value: "native hawaiian or other pacific islander"},
  {label: "Caucasian", value: "caucasian"},
  {label: "Prefer not to answer", value: "prefer not to answer"},
]

const hispanicOptions = [
  {label: "No", value: "no"},
  {label: "Yes", value: "yes"}
]

const insuranceOptions = [
  {"": ""},
  {"": ""},
  {"": ""},
  {"": ""},
  {"": ""},
  {"": ""},
  {"": ""}
]

function Hr(){
  return (
    <div className='max-w-lg'>
      <hr className="text-left w-52 h-1 mx-auto my-2 font-thin border-t-1 border-t-gray-400 rounded md:my-6"/>
    </div>
  )
}

function RadioInput({fieldName, elementId, label, value, formData, handleFormUpdate, errors, setErrors, children}){
  return (
    <>
      <input
        className="cursor-pointer"
        id={`form-radio-${elementId}`}
        type="radio"
        name={`${elementId}`}
        value={value}
        checked={formData[fieldName] === value}
        onChange={e => {
          handleFormUpdate(value, fieldName);
          if (e.target.checked) {
            setErrors({...errors, [fieldName]: false});
          }  // Additional check not needed because user cannot unselect radio button
        }}
      />
      <label htmlFor={`form-radio-${elementId}`} className='pl-1 text-sm text-gray-500 font-bold'>
        {label}
      </label>
      <br/>
      {children}
    </>
  )
}

function CheckboxInput({fieldName, elementId, label, value, formData, handleFormCheckboxUpdate, errors, setErrors, children}){
  return (
    <>
      <input
        className="cursor-pointer"
        id={`form-checkbox-${elementId}`}
        type="checkbox"
        name={`${elementId}`}
        value={value}
        checked={formData[fieldName].includes(value)}
        onChange={e => {
          handleFormCheckboxUpdate(value, fieldName, e.target.checked, errors, setErrors);
        }}
      />
      <label htmlFor={`form-checkbox-${elementId}`} className='pl-2 text-sm text-gray-500 font-bold'>
        {label}
      </label>
      <br/>
      {children}
    </>
  )
}

function RequiredText({ isError, customMessage }){
  const message = customMessage ? customMessage : "Please respond to this question.";
  const mdTextAlign = customMessage ? 'md:text-center' : 'md:text-left';
  return (
    <>
      {isError ?
        <p className={`text-red-500 text-xs text-center ${mdTextAlign} italic`}>{message}</p>
        :
        <div className='mt-4' />
      }
    </>
  )
}

export default function SurveyForm(){
  const [formData, setFormData] = useState(formFields);
  const [isLoading, setIsLoading] = useState(false);
  const [map_params, setMapParams] = useState();
  const [isError, setIsError] = useState(false);
  // const [isSubmittingPhaseTwo, setIsSubmittingPhaseTwo] = useState(false);
  const [phaseTwoComplete, setPhaseTwoComplete] = useState(false);
  // const [multiOptions, setMultiOptions] = useState([]);
  // const [selectedOptions, setSelectedOptions] = useState([]);
  const [errors1, setErrors1] = useState(formFieldErrors1);
  const [errors2, setErrors2] = useState(formFieldErrors2);
  // const navigate = useNavigate();


  // The 'Select' component from react-select is extremely complex to customize.
  // This snippet was taken from https://stackoverflow.com/a/51844542 and modified
  // // to match the styles of the normal form entries.

  // const customStyles = {
  //   control: (base, state) => ({
  //     ...base,
  //     background: formData['inconvenient_care_types'].length === 0 ? `rgb(229 231 235)` : `white`,
  //     // match with the menu
  //     borderRadius: state.isFocused ? "3px 3px 3px 3px" : 3,
  //     // Overwrittes the different states of border
  //     // borderColor: state.isFocused ? "yellow" : "green",
  //     // Removes weird border around container
  //     boxShadow: state.isFocused ? null : null,
  //     // "&:hover": {
  //     //   // Overwrittes the different states of border
  //     //   borderColor: state.isFocused ? "red" : "blue"
  //     // }
  //   })
  // };

  async function handleSubmit(isUpdate=false){
    let response;
    if (!isUpdate) {
      response = await postSurvey(formData);
    } else {
      response = await updateSurvey({...formData, survey_id: map_params.survey_id});
    }
    if (response.success) {
      if (!isUpdate) {
        setMapParams(response.data.lat_lons);
        // const mapParams = response.data.lat_lons;  // Update this to reflect map data from API
      } else {
        setPhaseTwoComplete(true);
      }
      // navigate('map', {
      //   state: {
      //     map_params: mapParams
      //   }
      // });
    } else {
      console.log('POST UNSUCCESSFUL');
      setIsError(true);
    }
    setIsLoading(false);
    // setIsSubmittingPhaseTwo(false);
  }

  function handleFormUpdate(value, fieldName){
    const formDataUpdate = {...formData}
    formDataUpdate[fieldName] = value;
    setFormData(formDataUpdate);
  }

  function handleFormCheckboxUpdate(value, fieldName, checked, errors, setErrors){
    const formDataUpdate = {...formData}
    if (checked) {
      formDataUpdate[fieldName] = [...formDataUpdate[fieldName], value]
      if (value === 'other') {
        if (formDataUpdate[`${fieldName}_other`]) {
          setErrors({...errors, [`${fieldName}_other`]: false});
        } else {
          setErrors({...errors, [`${fieldName}_other`]: true});
        }
      }
    } else {
      formDataUpdate[fieldName] = formDataUpdate[fieldName].filter(val => {return val !== value});
      if (value === 'other') {
        // Need to also set the DOM input field to null, otherwise
        // if 'other' is re-enabled, input field will show as a
        // validation error even though it has text.
        const el = document.getElementById(`input-${fieldName.replaceAll('_', '-')}-other`);
        if (el) el.value = null;
        formDataUpdate[`${fieldName}_other`] = null;
        setErrors({...errors, [`${fieldName}_other`]: false});
      }
    }

    if (formDataUpdate[fieldName].length > 0) {
      setErrors({...errors, [fieldName]: false});
    } else {
      setErrors({...errors, [fieldName]: true});
    }
    setFormData(formDataUpdate);
  }

  function setErrorState(fieldName, isValid, errors, setErrors){
    if (isValid) {
      setErrors({...errors, [fieldName]: false});
    } else {
      setErrors({...errors, [fieldName]: true});
    }
  }
  // const formHeaderCSS = 'text-lg text-slate-800 font-bold text-left';
  // const inputDivCSS = 'pt-2 text-left';
  // const inputBoxCSS = 'shadow appearance-none border px-2 rounded';

  const hasErrors1 = !Object.values(errors1).every(value => value === false);
  const hasErrors2 = !Object.values(errors2).every(value => value === false);

  return (
    <div>
      {isLoading ?
        <div className="w-full mx-3 p-10 mb-6 md:mb-0 text-gray-700 text-2xl font-bold text-left">Loading Providers ...</div>
        :
        <>
          {!map_params &&
            <div className="flex flex-col justify-center items-center" style={{marginLeft: isMobile ? "" : "35px", height: "440px"}}>
              <form className="w-full max-w-3xl p-4">

                <div className="flex flex-wrap -mx-3 mb-2">

                  <div className="w-full text-2xl font-thin text-gray-800 mx-3 my-3 md:mb-4">Tell us about you and your background</div>
                  {/* RELATIONSHIP TO PKD */}
                  <div id='relationship-to-pkd' className="w-full text-left mx-3 mb-6 md:mb-4">
                    <RequiredText isError={errors1.relationship_to_pkd} />
                    <label
                      className="block uppercase tracking-wide text-gray-500 text-sm font-bold mb-2 text-center md:text-left" htmlFor="relationship-to-pkd"
                    >
                      Your relationship to Polycystic Kidney Disease
                    </label>
                    <div className='text-left'>

                      <RadioInput
                        fieldName='relationship_to_pkd'
                        elementId='has-pkd'
                        label='I have PKD'
                        value='has pkd'
                        formData={formData}
                        handleFormUpdate={handleFormUpdate}
                        errors={errors1}
                        setErrors={setErrors1}
                      >
                        <input
                          // className="border disabled:bg-gray-200 border-gray-400 rounded"
                          className={`disabled:bg-gray-200 disabled:hidden appearance-none block w-full text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white`}
                          type='text'
                          placeholder='eGFR:'
                          disabled={formData.relationship_to_pkd !== 'has pkd'}
                          onChange={e=>{
                            handleFormUpdate(e.target.value, 'relationship_to_pkd_egfr');
                          }}
                          onBlur={e => {
                            setErrorState('relationship_to_pkd_egfr', e.target.value, errors1, setErrors1);
                          }}
                        />
                      </RadioInput>
                      <RadioInput
                        fieldName='relationship_to_pkd'
                        elementId='is-caregiver'
                        label='I am a caregiver or close family member'
                        value='caregiver'
                        formData={formData}
                        handleFormUpdate={handleFormUpdate}
                        errors={errors1}
                        setErrors={setErrors1}
                      />
                      <RadioInput
                        fieldName='relationship_to_pkd'
                        elementId='other'
                        label='Other (e.g. Researcher, friend)'
                        value='other'
                        formData={formData}
                        handleFormUpdate={handleFormUpdate}
                        errors={errors1}
                        setErrors={setErrors1}
                      />
                    </div>
                  </div>

                  {/* HOME STREET ADDRESS */}
                  <div className="w-full mx-3 mb-2 md:mb-2">
                    <label className="block uppercase tracking-wide text-gray-500 text-sm font-bold mb-2" htmlFor="first-name">
                      Home Street Address
                    </label>
                    <input
                      className={`appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 leading-tight ${errors1['street_address'] ? 'border-red-400' : 'border-gray-200'} focus:outline-none focus:bg-white`}
                      id="first-name"
                      type="text"
                      placeholder="5555 Wallaby Ln."
                      onChange={e => handleFormUpdate(e.target.value, 'street_address')}
                      onBlur={e => {
                        setErrorState('street_address', e.target.value, errors1, setErrors1);
                      }}
                    />
                    {/* <p className="text-red-500 text-xs italic">Please fill out this field.</p>*/}
                  </div>

                  {/* HOME CITY */}
                  <div className="w-1/2 md:w-1/3 lg:w-1/3 px-3 mb-2 md:mb-0">
                    <label className="block uppercase tracking-wide text-gray-500 text-sm font-bold mb-2" htmlFor="city">
                      City
                    </label>
                    <input
                      className={`appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 leading-tight ${errors1['city'] ? 'border-red-400' : 'border-gray-200'} focus:outline-none focus:bg-white focus:border-gray-500`}
                      id="city"
                      type="text"
                      placeholder="Albuquerque"
                      onChange={e => handleFormUpdate(e.target.value, 'city')}
                      onBlur={e => {
                        setErrorState('city', e.target.value, errors1, setErrors1);
                      }}
                    />
                  </div>

                  {/* HOME STATE */}
                  <div className="w-1/2 px-3 md:mb-0 mb-2 md:w-1/3 lg:w-1/3">
                    <label className="block uppercase tracking-wide text-gray-500 text-sm font-bold mb-2" htmlFor="state">
                      State
                    </label>
                    <div className="relative">
                      <select
                        className={`block appearance-none w-full bg-gray-200 border text-gray-700 py-3 px-4 pr-4 md:pr-4 rounded ${errors1['state'] ? 'border-red-400' : 'border-gray-200'} leading-tight focus:outline-none focus:bg-white focus:border-gray-500`}
                        id="state"
                        defaultValue={0}
                        onChange={e => handleFormUpdate(e.target.value, 'state')}
                        onBlur={e => {
                          setErrorState('state', e.target.value !== "0", errors1, setErrors1);
                        }}
                      >
                        <option disabled className="text-center" value={0}> -- Select -- </option>
                        {states.map((state, i) => (
                          <option key={`state-${i}`} value={state.toLowerCase()}>{state}</option>
                        ))}
                      </select>
                      <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                        <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
                      </div>
                    </div>
                  </div>

                  {/* HOME ZIPCODE - USA ONLY! */}
                  <div className="w-full md:w-1/3 px-3 mb-4 md:mb-0">
                    <label className="block uppercase tracking-wide text-gray-500 text-sm font-bold mb-2" htmlFor="zip">
                      Zip Code
                    </label>
                    <input
                      className={`appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 leading-tight ${errors1['zip_code'] ? 'border-red-400' : 'border-gray-200'} focus:outline-none focus:bg-white focus:border-gray-500`}
                      id="zip"
                      type="text"
                      placeholder="90210"
                      onChange={e => {

                        const isValidPartialZip = isValidInt(e.target.value) && e.target.value.length <= 5;
                        const isValid5digitZip = e.target.value && isValidInt(e.target.value) && e.target.value.length === 5
                        if (isValidPartialZip) {
                          handleFormUpdate(e.target.value, 'zip_code')
                        } else {
                          e.target.value = formData.zip_code;
                        }
                        if (isValid5digitZip) {
                          setErrorState('zip_code', isValidPartialZip, errors1, setErrors1);
                        }
                      }}
                      onBlur={e => {
                        const isValid5digitZip = e.target.value && isValidInt(e.target.value) && e.target.value.length === 5;
                        setErrorState('zip_code', isValid5digitZip, errors1, setErrors1);
                      }}
                    />
                  </div>

                  {/* AGE */}
                  <div className="w-full mx-3 mt-2 mb-3 md:w-1/5 md:pt-4 md:mb-0">
                    <label 
                      className="block uppercase tracking-wide text-gray-500 text-sm font-bold mb-2 md:mb-7"
                      htmlFor="form-age"
                    >
                      What is your age?
                    </label>
                    <input
                      className={`appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 leading-tight ${errors1['age'] ? 'border-red-400' : 'border-gray-200'} focus:outline-none focus:bg-white focus:border-gray-500`}
                      id="form-age"
                      type="number"
                      onChange={e => handleFormUpdate(e.target.value, 'age')}
                      onBlur={e => {
                        const isValid = e.target.value && isValidInt(e.target.value) && parseInt(e.target.value) > 0;
                        setErrorState('age', isValid, errors1, setErrors1);
                      }}
                    />
                  </div>

                  {/* GENDER */}
                  <div id='gender' className="w-full mx-3 mt-2 mb-3 md:w-1/5 md:pt-4 md:mb-0">
                    <label className="block uppercase tracking-wide text-gray-500 text-sm font-bold mb-2" htmlFor="gender">
                     What is your gender?
                    </label>
                    <div className="relative">
                      <select
                        className={`block text-center appearance-none w-full bg-gray-200 border text-gray-700 py-3 px-4 pr-8 rounded leading-tight ${errors1['gender'] ? 'border-red-400' : 'border-gray-200'} focus:outline-none focus:bg-white focus:border-gray-500`}
                        id="gender-option"
                        defaultValue={0}
                        onChange={e =>handleFormUpdate(e.target.value, 'gender')}
                        onBlur={e => {
                          setErrorState('gender', e.target.value !== "0", errors1, setErrors1);
                        }}
                      >
                        <option disabled value={0}> -- Select -- </option>
                        {genderOptions.map((option, i) => (
                          <option key={`gender-${i}`} value={option.value}>{option.label}</option>
                        ))}
                      </select>
                      <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                        <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
                      </div>
                    </div>
                  </div>

                  {/* RACE */}
                  <div id='race' className="w-full mx-3 mt-2 mb-3 md:w-1/5 md:pt-4 md:mb-0">
                    <label className="block uppercase tracking-wide text-gray-500 text-sm font-bold mb-2" htmlFor="race">
                     What is your race?
                    </label>
                    <div className="relative">
                      <select
                        className={`block text-center appearance-none w-full bg-gray-200 border text-gray-700 py-3 px-4 pr-8 rounded leading-tight ${errors1['race'] ? 'border-red-400' : 'border-gray-200'} focus:outline-none focus:bg-white focus:border-gray-500`}
                        id="race-option"
                        defaultValue={0}
                        onChange={e => handleFormUpdate(e.target.value, 'race')}
                        onBlur={e => {
                          setErrorState('race', e.target.value !== "0", errors1, setErrors1);
                        }}
                      >
                        <option disabled value={0}> -- Select -- </option>
                        {raceOptions.map((option, i) => (
                          <option key={`race-${i}`} value={option.value}>{option.label}</option>
                        ))}
                      </select>
                      <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                        <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
                      </div>
                    </div>
                  </div>

                  {/* HISPANIC OR LATINO */}
                  <div id='hispanic' className="w-full mx-3 mt-2 mb-3 md:w-1/5 md:pt-4 md:mb-0">
                    <label className="block uppercase tracking-wide text-gray-500 text-sm font-bold mb-2" htmlFor="hispanic">
                     Are you Hispanic/Latino?
                    </label>
                    <div className="relative">
                      <select
                        className={`block text-center appearance-none w-full bg-gray-200 border text-gray-700 py-3 px-4 pr-8 rounded leading-tight ${errors1['is_hispanic'] ? 'border-red-400' : 'border-gray-200'} focus:outline-none focus:bg-white focus:border-gray-500`}
                        id="hispanic-option"
                        defaultValue={0}
                        onChange={e => handleFormUpdate(e.target.value, 'is_hispanic')}
                        onBlur={e => {
                          setErrorState('is_hispanic', e.target.value !== "0", errors1, setErrors1);
                        }}
                      >
                        <option disabled value={0}> -- Select -- </option>
                        {hispanicOptions.map((option, i) => (
                          <option key={`hispanic-${i}`} value={option.value}>{option.label}</option>
                        ))}
                      </select>
                      <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                        <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
                      </div>
                    </div>
                  </div>

                  {/* HIGHEST EDUCATION */}
                  <div id='education' className="w-full mx-3 md:mt-6 md:mb-4 mt-2 mb-3">
                    <label className="block uppercase tracking-wide text-gray-500 text-sm font-bold mb-2" htmlFor="education">
                      What is your highest level of education?
                    </label>
                    <div className="relative">
                      <select
                        className={`block text-center appearance-none w-full bg-gray-200 border text-gray-700 py-3 px-4 pr-8 rounded leading-tight ${errors1['education'] ? 'border-red-400' : 'border-gray-200'} focus:outline-none focus:bg-white focus:border-gray-500`}
                        id="education-option"
                        defaultValue={0}
                        onChange={e => handleFormUpdate(e.target.value, 'education')}
                        onBlur={e => {
                          setErrorState('education', e.target.value !== "0", errors1, setErrors1);
                        }}
                      >
                        <option disabled value={0}> -- Select -- </option>
                        {educationOptions.map((option, i) => (
                          <option key={`education-${i}`} value={option.value}>{option.label}</option>
                        ))}
                      </select>
                      <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                        <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
                      </div>
                    </div>
                  </div>

                  {/* INSURANCE */}
                  <div id='insurance-types' className="w-full mt-4 mx-3 mb-6 md:mb-0">
                    <RequiredText isError={errors1.insurance_types} />
                    <label
                      className="block uppercase tracking-wide text-gray-500 text-sm font-bold mb-2 md:text-left" htmlFor="insurance-types"
                    >
                      Please describe your health insurance/coverage (Check all that apply)
                    </label>
                    <div className='text-left'>
                      <CheckboxInput
                        fieldName='insurance_types'
                        elementId='insurance-types-group'
                        label="Group health insurance (I receive health insurance through my employer, my spouse’s employer, or I am still covered under my parent/guardian’s employer’s plan.)"
                        value='group'
                        formData={formData}
                        errors={errors1}
                        setErrors={setErrors1}
                        handleFormCheckboxUpdate={handleFormCheckboxUpdate}
                      />
                      <CheckboxInput
                        fieldName='insurance_types'
                        elementId='insurance-types-individual'
                        label="Individual health insurance (I purchase my own health insurance either through a broker or either a state or federal marketplace.)"
                        value='individual'
                        formData={formData}
                        errors={errors1}
                        setErrors={setErrors1}
                        handleFormCheckboxUpdate={handleFormCheckboxUpdate}
                      />
                      <CheckboxInput
                        fieldName='insurance_types'
                        elementId='insurance-types-medicare'
                        label="Medicare"
                        value='medicare'
                        formData={formData}
                        handleFormCheckboxUpdate={handleFormCheckboxUpdate}
                        errors={errors1}
                        setErrors={setErrors1}
                      />
                      <CheckboxInput
                        fieldName='insurance_types'
                        elementId='insurance-types-medicaid'
                        label="Medicaid"
                        value='medicaid'
                        formData={formData}
                        handleFormCheckboxUpdate={handleFormCheckboxUpdate}
                        errors={errors1}
                        setErrors={setErrors1}
                      />
                      <CheckboxInput
                        fieldName='insurance_types'
                        elementId='insurance-types-tricare'
                        label="TRICARE/Veterans benefits"
                        value='tricare'
                        formData={formData}
                        handleFormCheckboxUpdate={handleFormCheckboxUpdate}
                        errors={errors1}
                        setErrors={setErrors1}
                      />
                      <CheckboxInput
                        fieldName='insurance_types'
                        elementId='insurance-types-other'
                        label="Other"
                        value='other'
                        formData={formData}
                        handleFormCheckboxUpdate={handleFormCheckboxUpdate}
                        errors={errors1}
                        setErrors={setErrors1}
                      >
                        <input
                          // className="border disabled:bg-gray-200 border-gray-400 rounded"
                          className={`disabled:bg-gray-200 disabled:hidden appearance-none block w-full text-gray-700 border rounded py-3 px-4 mb-3 leading-tight ${formData.insurance_types.includes('other') && !formData.insurance_types_other ? 'border-red-400' : 'border-gray-200'} focus:outline-none focus:bg-white`}
                          type='text'
                          placeholder='Type Response'
                          disabled={!formData.insurance_types.includes('other')}
                          onChange={e=>{
                            handleFormUpdate(e.target.value, 'insurance_types_other');
                          }}
                          onBlur={e => {
                            setErrorState('insurance_types_other', e.target.value, errors1, setErrors1);
                          }}
                        />
                      </CheckboxInput>
                    </div>
                  </div>


                  <div className="w-full text-2xl font-thin text-gray-800 mx-3 my-3 md:mb-4">Tell us about your community and PKD journey</div>

                  {/* FREQUENTED LOCATIONS */}
                  <div className="w-full mx-3 mb-6 md:mt-4 md:mb-0">
                    <label className="block uppercase tracking-wide text-gray-500 text-sm font-bold mb-2 md:text-left" htmlFor="frequented-locations">
                      What are two non-medical locations outside the home or office you visit most regularly?
                    </label>
                    <input
                      className={`text-sm md:text-base appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight ${errors1['frequented_location_1'] ? 'border-red-400' : 'border-gray-200'} focus:outline-none focus:bg-white`}
                      id="frequented-locations"
                      type="text"
                      placeholder="e.g., Kroger grocery store, friend’s home"
                      onChange={(e)=>handleFormUpdate(e.target.value, 'frequented_location_1')}
                      onBlur={e => {
                        setErrorState('frequented_location_1', e.target.value, errors1, setErrors1);
                      }}
                    />
                    <input
                      className={`text-sm md:text-base appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight ${errors1['frequented_location_2'] ? 'border-red-400' : 'border-gray-200'} focus:outline-none focus:bg-white`}
                      id="frequented-locations"
                      type="text"
                      placeholder="e.g., Kroger grocery store, friend’s home"
                      onChange={(e)=>handleFormUpdate(e.target.value, 'frequented_location_2')}
                      onBlur={e => {
                        setErrorState('frequented_location_2', e.target.value, errors1, setErrors1);
                      }}
                    />
                  </div>

                  {/* PKD HEALTHCARE ISSUE */}
                  <div className="w-full mx-3 mb-6 md:mt-4 md:mb-0">
                    <label className="block uppercase tracking-wide text-gray-500 text-sm font-bold mb-2 md:text-left" htmlFor="pkd-healthcare-issue">
                      What 2 types of healthcare have been most challenging to access during your journey with PKD?
                    </label>
                    <input
                      className={`text-sm md:text-base appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight ${errors1['pkd_healthcare_issue_1'] ? 'border-red-400' : 'border-gray-200'} focus:outline-none focus:bg-white`}
                      id="pkd-healthcare-issue-1"
                      type="text"
                      placeholder="e.g., PKD specialists, other specialists, transplant centers, dialysis centers, lab testing locations"
                      onChange={(e)=>handleFormUpdate(e.target.value, 'pkd_healthcare_issue_1')}
                      onBlur={e => {
                        setErrorState('pkd_healthcare_issue_1', e.target.value, errors1, setErrors1);
                      }}
                    />
                    <input
                      className={`text-sm md:text-base appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight ${errors1['pkd_healthcare_issue_2'] ? 'border-red-400' : 'border-gray-200'} focus:outline-none focus:bg-white`}
                      id="pkd-healthcare-issue-2"
                      type="text"
                      placeholder="e.g., PKD specialists, other specialists, transplant centers, dialysis centers, lab testing locations"
                      onChange={(e)=>handleFormUpdate(e.target.value, 'pkd_healthcare_issue_2')}
                      onBlur={e => {
                        setErrorState('pkd_healthcare_issue_2', e.target.value, errors1, setErrors1);
                      }}
                    />
                  </div>

                  {/* PKD JOURNEY FRUSTRATIONS */}
                  <div id='pkd-journey-issues' className="w-full mx-3 mt-4 mb-6 md:mb-0">
                    <RequiredText isError={errors1.pkd_journey_issues} />
                    <label
                      className="block uppercase tracking-wide text-gray-500 text-sm font-bold mb-2 md:text-left" htmlFor="pkd-issue"
                    >
                      What are 3 factors that have been particularly frustrating during your journey with PKD?
                    </label>
                    <div className='text-left'>
                      <CheckboxInput
                        fieldName='pkd_journey_issues'
                        elementId='pkd-journey-issues-finance'
                        label="Financial/insurance issues"
                        value='financial/insurance'
                        formData={formData}
                        handleFormCheckboxUpdate={handleFormCheckboxUpdate}
                        errors={errors1}
                        setErrors={setErrors1}
                      />
                      <CheckboxInput
                        fieldName='pkd_journey_issues'
                        elementId='pkd-journey-issues-logistics'
                        label="Logistics of getting care (e.g., doctor availability, transportation)"
                        value='logistics'
                        formData={formData}
                        handleFormCheckboxUpdate={handleFormCheckboxUpdate}
                        errors={errors1}
                        setErrors={setErrors1}
                      />
                      <CheckboxInput
                        fieldName='pkd_journey_issues'
                        elementId='pkd-journey-issues-specialist'
                        label="Finding a specialist who knows PKD specifically"
                        value='specialist'
                        formData={formData}
                        handleFormCheckboxUpdate={handleFormCheckboxUpdate}
                        errors={errors1}
                        setErrors={setErrors1}
                      />
                      <CheckboxInput
                        fieldName='pkd_journey_issues'
                        elementId='pkd-journey-issues-transplant'
                        label="Finding and working with a transplant center"
                        value='transplant'
                        formData={formData}
                        handleFormCheckboxUpdate={handleFormCheckboxUpdate}
                        errors={errors1}
                        setErrors={setErrors1}
                      />
                      <CheckboxInput
                        fieldName='pkd_journey_issues'
                        elementId='pkd-journey-issues-communication'
                        label="Communicating with providers or coordinating care across doctors, pharmacies, labs, etc."
                        value='communication'
                        formData={formData}
                        handleFormCheckboxUpdate={handleFormCheckboxUpdate}
                        errors={errors1}
                        setErrors={setErrors1}
                      />
                      <CheckboxInput
                        fieldName='pkd_journey_issues'
                        elementId='pkd-journey-issues-other'
                        label="Other"
                        value='other'
                        formData={formData}
                        handleFormCheckboxUpdate={handleFormCheckboxUpdate}
                        errors={errors1}
                        setErrors={setErrors1}
                      >
                        <input
                          id='input-pkd-journey-issues-other'
                          className={`disabled:bg-gray-200 disabled:hidden appearance-none block w-full text-gray-700 border rounded py-3 px-4 mb-3 leading-tight ${formData.pkd_journey_issues.includes('other') && !formData.pkd_journey_issues_other ? 'border-red-400' : 'border-gray-200'} focus:outline-none focus:bg-white`}
                          type='text'
                          disabled={!formData.pkd_journey_issues.includes('other')}
                          placeholder='Type Response'
                          onChange={e=>{
                            handleFormUpdate(e.target.value, 'pkd_journey_issues_other');
                          }}
                          onBlur={e => {
                            setErrorState('pkd_journey_issues_other', e.target.value, errors1, setErrors1);
                          }}
                        />
                      </CheckboxInput>
                    </div>
                  </div>

                </div>
                
                {/* SUBMIT BUTTON 1 */}
                <div className="mb-2">
                  <RequiredText isError={errors1.zip_code} customMessage={'Please fill out "Zip Code".'} />
                </div>
                <button
                  id="submit-button-1"
                  className='bg-blue-600 text-white rounded py-1 px-2 hover:bg-blue-400 active:bg-blue-500 disabled:opacity-30 disabled:cursor-default disabled:bg-blue-600'
                  // disabled={hasErrors1}
                  disabled={errors1.zip_code}
                  onClick={(e)=>{
                    e.preventDefault();
                    setIsLoading(true);
                    handleSubmit();
                  }}
                >
                  Submit
                </button>
                <div className="text-sky-800 mt-5 text-sm font-bold">
                  Respond to all questions and press "Submit" to see providers near you
                </div>
              </form>
            </div>
          }

          {/* MAP PLOT */}
          {map_params &&
            <div className="flex flex-col justify-center items-center">
              <div className="my-2 text-2xl text-gray-600 font-bold">Providers Near You</div>
              <div className="mb-1 text-sm text-gray-600 font-semibold">Hover over a location for details</div>
              <Hr />
              <div className="-pl-20" style={{paddingLeft: "-25px", position: "-50px"}}><MapPlot map_params={map_params} width={700} height={500} /></div>
              <Hr />

              <div className="text-left text-sm ml-16" style={{width: "700px"}}>
                The following define the care types shown above; they are the locations within 500 miles that are nearest to your address.
                <br/><br/>
                <span className="font-bold">Nephrologist</span>: A nephrologist who likely does not specialize in rare kidney diseases
                <br/>
                <span className="font-bold">Glomerular Specialist</span>: A physician who specializes in a rare glomerular disease, but not necessarily IGAN
                <br/>
                <span className="font-bold">CKD Support Group</span>: An in-person support group for chronic kidney disease patients; online support groups not shown here
                <br/>
                <span className="font-bold">IGAN Specialist</span>: A nephrologist who is known to specialize in IGAN, as verified by the IGA Nephropathy Foundation
                <br/>
                <span className="font-bold">Clinical Trial</span>: Location of an in-person IGAN clinical trial
                <br/><br/>
                Please note that if you want to work with one of these locations, you should verify the details yourself first.
              </div>

            </div>
          }

          {map_params &&
            <div className="flex flex-col justify-center items-center">
              {!phaseTwoComplete ?
                <form className="w-full max-w-lg">
                  <div className="flex flex-wrap -mx-3 mb-2">

                    {/* CARE LOCATION INTERESTS */}
                    <div id='care-location-interests' className="w-full mx-3 mt-4 mb-6 md:mb-5">
                      <label
                        className="block uppercase tracking-wide text-gray-500 text-sm font-bold mb-2" htmlFor="care-location-interests"
                      >
                        What else would you want to know about these care locations? (Check all that apply)
                      </label>
                      <div className='text-left mx-8'>
                        <CheckboxInput
                          fieldName='care_location_interests'
                          elementId='care-location-interests-insurance-accepted'
                          label="Insurances accepted"
                          value='insurances accepted'
                          formData={formData}
                          handleFormCheckboxUpdate={handleFormCheckboxUpdate}
                          errors={errors2}
                          setErrors={setErrors2}
                        />
                        <CheckboxInput
                          fieldName='care_location_interests'
                          elementId='care-location-interests-review-doctors'
                          label="Reviews of doctors"
                          value='reviews of doctors'
                          formData={formData}
                          handleFormCheckboxUpdate={handleFormCheckboxUpdate}
                          errors={errors2}
                          setErrors={setErrors2}
                        />
                        <CheckboxInput
                          fieldName='care_location_interests'
                          elementId='care-location-interests-appointment-availability'
                          label="Appointment availability"
                          value='appointment availability'
                          formData={formData}
                          handleFormCheckboxUpdate={handleFormCheckboxUpdate}
                          errors={errors2}
                          setErrors={setErrors2}
                        />
                        <CheckboxInput
                          fieldName='care_location_interests'
                          elementId='care-location-interests-non-med-services'
                          label="Non-medical services provided (e.g., financial or transportation assistance)"
                          value='non-medical services'
                          formData={formData}
                          handleFormCheckboxUpdate={handleFormCheckboxUpdate}
                          errors={errors2}
                          setErrors={setErrors2}
                        />
                        <CheckboxInput
                          fieldName='insurance_types'
                          elementId='insurance-types-other'
                          label="Other"
                          value='other'
                          formData={formData}
                          handleFormCheckboxUpdate={handleFormCheckboxUpdate}
                          errors={errors2}
                          setErrors={setErrors2}
                        >
                          <input
                            // className="border disabled:bg-gray-200 border-gray-400 rounded"
                            className={`disabled:bg-gray-200 disabled:hidden appearance-none block w-full text-gray-700 border rounded py-3 px-4 mb-3 leading-tight ${formData.care_location_interests.includes('other') && !formData.care_location_interests_other ? 'border-red-400' : 'border-gray-200'} focus:outline-none focus:bg-white`}
                            type='text'
                            placeholder='Type Response'
                            disabled={!formData.care_location_interests.includes('other')}
                            onChange={e=>{
                              handleFormUpdate(e.target.value, 'care_location_interests_other');
                            }}
                            onBlur={e => {
                              setErrorState('care_location_interests_other', e.target.value, errors2, setErrors2);
                            }}
                          />
                        </CheckboxInput>
                      </div>
                    </div>

                    {/* ADDITIONAL CARE MAP LOCATIONS */}
                    <div className="w-full mx-3 mx-5 mb-6 md:mt-4 md:mb-10">
                      <label className="block uppercase tracking-wide text-gray-500 text-sm font-bold mb-2 mx-2" htmlFor="additional-care-location-type">
                        What other types of care locations would you like to see on this map?
                      </label>
                      <input
                        className="appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 mb-2 leading-tight focus:outline-none focus:bg-white"
                        id="additional-care-location-type-1"
                        type="text"
                        placeholder="e.g., transplant centers, laboratories, pharmacies, other"
                        onChange={(e)=>handleFormUpdate(e.target.value, 'additional_care_location_type_1')}
                      />
                      <input
                        className="appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 mb-2 leading-tight focus:outline-none focus:bg-white"
                        id="additional-care-location-type-2"
                        type="text"
                        placeholder="e.g., transplant centers, laboratories, pharmacies, other"
                        onChange={(e)=>handleFormUpdate(e.target.value, 'additional_care_location_type_2')}
                      />
                    </div>

                    {/* RELEVANCEY */}
                    <div id='relevant' className="w-full mx-3 mb-2 md:mb-2">
                      <label
                        className="block uppercase tracking-wide text-gray-500 text-sm font-bold mb-2 mx-2" htmlFor="relevant"
                      >
                        Are you currently treated at any of these locations and/or do they look like reasonable options for you?
                      </label>
                      <div className='text-left ml-8'>
                        <RadioInput
                          fieldName='is_relevant'
                          elementId='relevant-no'
                          label='No'
                          value={false}
                          formData={formData}
                          handleFormUpdate={handleFormUpdate}
                          errors={errors2}
                          setErrors={setErrors2}
                        />
                        <RadioInput
                          fieldName='is_relevant'
                          elementId='relevant-yes'
                          label='Yes'
                          value={true}
                          formData={formData}
                          handleFormUpdate={handleFormUpdate}
                          errors={errors2}
                          setErrors={setErrors2}
                        />
                      </div>
                    </div>

                    {/* EMAIL */}
                    <div className="w-full mx-5 px-3 pt-2 mt-4 mb-6 md:mb-0">
                      <label className="block uppercase tracking-wide text-gray-500 text-sm font-bold mb mx-2" htmlFor="email">
                        Provide your email address to get more information on new features and support resources (Not required)
                      </label>
                      <input
                        className={`appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 leading-tight ${errors2['email'] ? 'border-red-400' : 'border-gray-200'} focus:outline-none focus:bg-white`}
                        id="email"
                        type="text"
                        placeholder="name@email.com"
                        onChange={(e)=>handleFormUpdate(e.target.value, 'email')}
                        onBlur={e => {
                          setErrorState('email', isValidEmail(e.target.value), errors2, setErrors2);
                        }}
                      />
                    </div>

                  </div>

                  {/* SUBMIT BUTTON 2 */}
                  <button
                    id="submit-button-2"
                    className='bg-blue-600 text-white rounded py-1 px-2 hover:bg-blue-400 active:bg-blue-500 disabled:opacity-30 disabled:cursor-default disabled:bg-blue-600'
                    disabled={hasErrors2}
                    onClick={(e)=>{
                      e.preventDefault();
                      // setIsSubmittingPhaseTwo(true);
                      handleSubmit(true);
                    }}
                  >
                    Submit
                  </button>
                </form>
                :
                <div className="my-2 text-lg text-gray-600">Thank You for your response!</div>
              }
            </div>
          }
        </>
      }
      {(isError && (!map_params || !phaseTwoComplete)) &&
        <div className="w-full mx-3 p-4 mb-6 md:mb-0 text-gray-700 text-2xl font-bold text-left">Server Error 500</div>
      }
      <br/>
      <br/>
    </div>
  )
}

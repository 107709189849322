import env from "@beam-australia/react-env";

const baseURL = env("API_URL");

async function formatResponse(response){
  if (!response.stubbed) {  // Checks if this is test data.
    if (response.ok) {
      const json = response;
      json.success = true;
      json.data = await response.json();
      return response;
    } else {
      return {success: false}
    }
  }
}

async function formatErrorResponse(err){
  const response = {success: false}
  
  if (err.name === 'AbortError') {
    response.status = 503;
  } else {
    response.status = 500;
  }
  
  response.errMessage = err.name;
  return response;
}

function getFetchOptionsWithAuth(method, payload, authCreds, timeout=10000) {
  const fullPayload = {
    method: method,
    body: JSON.stringify(payload),
    mode: "cors",
    cache: "no-cache",
    credentials: 'include',
    headers: {
      "Accept": "application/json",
      "Content-Type": "application/json",
      "Authorization": authCreds
    },
    redirect: 'follow',
    referrer: 'no-referrer'
  }
  
  const abortablePayload = appendAbortableController(fullPayload, timeout);
    
  return abortablePayload
}

function getFetchOptions(method, payload, timeout=10000) {
  const fullPayload = {
    method: method,
    body: JSON.stringify(payload),
    mode: "cors",
    cache: "no-cache",
    credentials: 'include',
    headers: {
      "Accept": "application/json",
      "Content-Type": "application/json"
    },
    redirect: 'follow',
    referrer: 'no-referrer'
  }
  
  const abortablePayload = appendAbortableController(fullPayload, timeout);
    
  return abortablePayload
}

function includeCreds(method, timeout=10000) {
  const fullPayload = {
    method: method,
    mode: "cors",
    cache: "no-cache",
    credentials: 'include',
    headers: {
      "Accept": "application/json",
      "Content-Type": "application/json"
    },
    redirect: 'follow',
    referrer: 'no-referrer'
  }

  const abortablePayload = appendAbortableController(fullPayload, timeout);
    
  return abortablePayload
}

function appendAbortableController(fullPayload, timeout) {
  if (timeout > 0) {
    const controller = new AbortController();
    const signal = controller.signal;
    setTimeout(() => controller.abort(), timeout);
    fullPayload.signal = signal
  }

  return fullPayload;
}

export async function postSurvey(payload) {
  try {
    const response = await fetch(`${baseURL}/survey_completed`, getFetchOptions('POST', payload));
    return formatResponse(response)
  } catch (err) {
    const errResponse = formatErrorResponse(err);
    return errResponse;
  }
}

export async function updateSurvey(payload) {
  try {
    const response = await fetch(`${baseURL}/survey_update`, getFetchOptions('PATCH', payload));
    return formatResponse(response)
  } catch (err) {
    const errResponse = formatErrorResponse(err);
    return errResponse;
  }
}

export async function getTypeFormMapResult(responseId) {
  try {
    const response = await fetch(`${baseURL}/map_data?response_id=${responseId}`, includeCreds('GET'));
    return formatResponse(response)
  } catch (err) {
    const errResponse = formatErrorResponse(err);
    return errResponse;
  }
}

export async function getMap2Result(address, user_lat, user_lng) {
  try {
    const response = await fetch(`${baseURL}/map_data2?address=${address}&user_lat=${user_lat}&user_lng=${user_lng}`, includeCreds('POST'));
    return formatResponse(response)
  } catch (err) {
    const errResponse = formatErrorResponse(err);
    return errResponse;
  }
}

import { useState, useCallback } from 'react';
import MapPlot from './MapPlot';
import { getTypeFormMapResult } from './API';
import { Widget } from '@typeform/embed-react'
// import {isMobile} from 'react-device-detect';
// import useWindowDimensions from './useWindowDimensions';

function Hr(){
  return (
    <div className='max-w-lg'>
      <hr className="text-left w-52 h-1 mx-auto my-2 font-thin border-t-1 border-t-gray-400 rounded md:my-6"/>
    </div>
  )
}

function timeout(delay) {
  return new Promise( res => setTimeout(res, delay) );
}

export default function TypeFormMobile(){
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingMap, setIsLoadingMap] = useState(false);
  const [map_params, setMapParams] = useState();

  const isMobile = true;

  // const { height, width } = useWindowDimensions();

  async function handleTypeFormSubmit(responseId){
    setIsLoadingMap(true);
    await timeout(1500);  // No idea why this is here, but it's hard to test live.
    const response = await getTypeFormMapResult(responseId);
    // console.log(response)
    if (response.success) {
      setMapParams(response.data.lat_lons);

      // Importing @typeform/embed-react applies overflow:hidden to the body, so we need to remove it once we're done with Typeform.
      document.body.style.overflow = "auto";
    } else {
      setIsError(true);
    }
    setIsLoadingMap(false);
  }

  const cachedFn = useCallback(async e => {
    handleTypeFormSubmit(e.responseId);
    }
    , [setIsLoadingMap, setMapParams, setIsError]);

  return (
    // bg-white here prevents a parent site hosting the iFrame from overriding the margin color.
    <div className='!bg-white'>
      {isLoading ?
        <div 
          className="
            w-full
            mx-3
            p-10
            mb-6
            md:mb-0
            text-gray-700
            text-2xl
            font-bold
            text-left"
        >
          Loading Form ...
        </div>
        :
        <>
          {!map_params && !isError &&
            // Allegedly `data-tf-inline-on-mobile` is supposed to remove the "Welcome page" that is
            // forced upon all users on mobile. Ref: https://community.typeform.com/typeform-developers-44/welcome-screen-on-mobile-embedded-form-477
            <div className={`${isMobile ? 'm-1' : "m-8"} content-center`}>
              <Widget
                id="B3wVAbUM"  // B3wVAbUM is the Test typeform that fills in "Test" values.
                // style={{ width: '100%', height: isMobile ? `${height}px` : `${height - 80}px`}}
                className="my-form"
                inlineOnMobile // Prevents welcome page from loading on mobile.
                onSubmit={cachedFn}
                fullScreen
              />
            </div>
          }

          {/* MAP PLOT */}
          {map_params && !isError &&
            <div className="flex flex-col justify-center items-center !bg-white">
              <div className="my-2 text-2xl text-gray-600 font-bold">Providers Near You</div>
              <div className="mb-1 text-sm text-gray-600 font-semibold">Tap or hover over a location for details</div>
              <Hr />
              <div className="mx-5">
              <MapPlot map_params={map_params} height={500} />
              </div>
              <Hr />
              <div className="text-left text-sm px-5">
                The following define the care types shown above; they are the locations within 500 miles that are nearest to your address.
                <br/><br/>
                <span className="font-bold">Nephrologist</span>: A nephrologist who likely does not specialize in rare kidney diseases
                <br/>
                <span className="font-bold">Glomerular Specialist</span>: A physician who specializes in a rare glomerular disease, but not necessarily IGAN
                <br/>
                <span className="font-bold">CKD Support Group</span>: An in-person support group for chronic kidney disease patients; online support groups not shown here
                <br/>
                <span className="font-bold">IGAN Specialist</span>: A nephrologist who is known to specialize in IGAN, as verified by the IGA Nephropathy Foundation
                <br/>
                <span className="font-bold">Clinical Trial</span>: Location of an in-person IGAN clinical trial
                <br/><br/>
                Please note that if you want to work with one of these locations, you should verify the details yourself first.
              </div>
            </div>
          }

          {/* MAP LOADING */}
          {isLoadingMap && !isError &&
            <div>Loading map ...</div>
          }

          {/* ERROR PAGE */}
          {isError &&
            <div>
              <div className='mt-56 mx-96 text-lg font-bold'>
                We encountered an error submitting your information. It may be because you entered an invalid or non-US zip code. If you have questions, comments, or requests please email info@tealeafhealth.com
              </div>
            </div>
          }
        </>
      }
      <br/>
    </div>
  )
}

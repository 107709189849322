import React, { useState, useEffect } from "react";
import MapPlot from "./MapPlot2";
import { getMap2Result } from "./API";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import parse from "autosuggest-highlight/parse";
import { debounce } from "@mui/material/utils";

const GOOGLE_MAPS_API_KEY = "AIzaSyDFZfFt1bMI5VVZltTcG4htAEOtsxae0oQ";

function Hr() {
  return (
    <div className="w-100">
      <hr className="text-left w-4/5 h-1 mx-auto my-2 font-thin border-t-1 border-t-gray-400 rounded md:my-6" />
    </div>
  );
}

function loadScript(src, position, id) {
  if (!position) {
    return;
  }

  const script = document.createElement("script");
  script.setAttribute("async", "");
  script.setAttribute("id", id);
  script.src = src;
  position.appendChild(script);
}

const autocompleteService = { current: null };
const placesService = { current: null };

export default function Map2() {
  const MIN_LENGTH_TO_SEARCH = 3;
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingMap, setIsLoadingMap] = useState(false);
  const [map_params, setMapParams] = useState();

  const [value, setValue] = React.useState(null);
  const [isSearching, setIsSearching] = useState(false);
  const [address, setAddress] = React.useState(null);
  const [inputValue, setInputValue] = React.useState("");
  const [options, setOptions] = React.useState([]);
  const loaded = React.useRef(false);

  if (typeof window !== "undefined" && !loaded.current) {
    if (!document.querySelector("#google-maps")) {
      loadScript(
        `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_API_KEY}&libraries=places`,
        document.querySelector("head"),
        "google-maps"
      );
    }

    loaded.current = true;
  }

  const fetch = React.useMemo(
    () =>
      debounce((request, callback) => {
        if (request.input.length < MIN_LENGTH_TO_SEARCH) {
          return;
        }
        setIsSearching(true);
        request.types = ["geocode"];
        request.fields = [""];
        autocompleteService.current.getPlacePredictions(request, callback);
      }, 500),
    []
  );

  async function handleFormSubmit(address, user_lat, user_lng) {
    setIsLoadingMap(true);
    setAddress(address);

    const response = await getMap2Result(address, user_lat, user_lng);
    if (response.success) {
      // Specifically order the results by type then distance.
      const careTypeOrder = [
        "IGAN Specialist",
        "In-person CKD support group",
        "Kidney Dietician",
        "Kidney Dietitian",
        "Glomerular Specialist",
        "Nephrologist",
        "Nephrologist-HMO",
        "Clinical Trial",
      ];
      response.data.lat_lons.providers.sort((a, b) => {
        const careTypeA = careTypeOrder.indexOf(a.care_type);
        const careTypeB = careTypeOrder.indexOf(b.care_type);
        if (careTypeA !== careTypeB) return careTypeA - careTypeB;

        // If care_type is the same, compare distance
        return a.distance - b.distance;
      });
      setMapParams(response.data.lat_lons);
    } else {
      setIsError(true);
    }
    setIsLoadingMap(false);
  }

  React.useEffect(() => {
    let active = true;

    if (!autocompleteService.current && window.google) {
      autocompleteService.current =
        new window.google.maps.places.AutocompleteService();
      placesService.current = new window.google.maps.places.PlacesService(
        document.createElement("div")
      );
    }
    if (!autocompleteService.current) {
      return undefined;
    }

    if (inputValue === "") {
      setOptions(value ? [value] : []);
      return undefined;
    }

    fetch({ input: inputValue }, (results) => {
      if (active) {
        let newOptions = [];
        if (value) {
          newOptions = [value];
        }

        if (results) {
          newOptions = [...newOptions, ...results];
        }

        setOptions(newOptions);
        setIsSearching(false);
      }
    });

    return () => {
      active = false;
    };
  }, [value, inputValue, fetch]);

  return (
    // bg-white here prevents a parent site hosting the iFrame from overriding the margin color.
    <div className="!bg-white" id="top">
      {isLoading ? (
        <div
          className="
            w-full
            mx-3
            p-10
            mb-6
            md:mb-0
            text-gray-700
            text-2xl
            font-bold
            text-left"
        >
          Loading Form ...
        </div>
      ) : (
        <>
          {!map_params && !isError && (
            <div
              className="max-w-md mx-auto bg-white overflow-hidden  md:max-w-2xl content-center"
              style={{ height: "90vh", marginTop: "20px" }}
            >
              <div className="my-2 text-2xl text-gray-600 font-bold">
                Where are you seeking care?
              </div>
              <Autocomplete
                id="google-map-autocomplete"
                className="mt-5"
                sx={{ width: "100%" }}
                getOptionLabel={(option) =>
                  typeof option === "string" ? option : option.description
                }
                filterOptions={(x) => x}
                options={options}
                autoComplete
                clearOnEscape={false}
                includeInputInList
                openOnFocus={false}
                filterSelectedOptions
                value={value}
                noOptionsText={
                  inputValue.length == 0
                    ? "Begin typing an address."
                    : inputValue.length < MIN_LENGTH_TO_SEARCH
                    ? "Keep typing..."
                    : isSearching
                    ? "Searching..."
                    : "Please try a different address."
                }
                onChange={(event, newValue) => {
                  setOptions(newValue ? [newValue, ...options] : options);
                  setValue(newValue);
                  placesService.current.getDetails(
                    {
                      placeId: newValue.place_id,
                    },
                    function (place, status) {
                      if (
                        status ===
                        window.google.maps.places.PlacesServiceStatus.OK
                      ) {
                        handleFormSubmit(
                          place.formatted_address,
                          place.geometry.location.lat(),
                          place.geometry.location.lng()
                        );
                      }
                    }
                  );
                }}
                onInputChange={(event, newInputValue) => {
                  if (newInputValue.length >= MIN_LENGTH_TO_SEARCH) {
                    setIsSearching(true);
                  }
                  setInputValue(newInputValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Enter an address, zip code, city, or country."
                    fullWidth
                  />
                )}
                renderOption={(props, option) => {
                  const matches =
                    option.structured_formatting.main_text_matched_substrings ||
                    [];

                  const parts = parse(
                    option.structured_formatting.main_text,
                    matches.map((match) => [
                      match.offset,
                      match.offset + match.length,
                    ])
                  );

                  return (
                    <li {...props}>
                      <Grid container alignItems="center">
                        <Grid
                          item
                          sx={{
                            width: "calc(100% - 44px)",
                            wordWrap: "break-word",
                          }}
                        >
                          {parts.map((part, index) => (
                            <Box
                              key={index}
                              component="span"
                              sx={{
                                fontWeight: part.highlight ? "bold" : "regular",
                              }}
                            >
                              {part.text}
                            </Box>
                          ))}
                          <Typography variant="body2" color="text.secondary">
                            {option.structured_formatting.secondary_text}
                          </Typography>
                        </Grid>
                      </Grid>
                    </li>
                  );
                }}
              />
            </div>
          )}

          {/* MAP PLOT */}
          {map_params && !isError && (
            <div
              className="max-w-md mx-auto bg-white  md:max-w-2xl content-center"
              style={{ marginTop: "20px" }}
            >
              <div className="my-2 text-2xl text-gray-600 font-bold">
                Providers Near You
              </div>
              <Autocomplete
                id="google-map-autocomplete"
                className="mt-5"
                sx={{ width: "100%" }}
                getOptionLabel={(option) =>
                  typeof option === "string" ? option : option.description
                }
                filterOptions={(x) => x}
                options={options}
                autoComplete
                clearOnEscape={false}
                includeInputInList
                openOnFocus={false}
                filterSelectedOptions
                value={value}
                noOptionsText={
                  inputValue.length == 0
                    ? "Begin typing an address."
                    : inputValue.length < MIN_LENGTH_TO_SEARCH
                    ? "Keep typing..."
                    : isSearching
                    ? "Searching..."
                    : "Please try a different address."
                }
                onChange={(event, newValue) => {
                  setOptions(newValue ? [newValue, ...options] : options);
                  setValue(newValue);
                  placesService.current.getDetails(
                    {
                      placeId: newValue.place_id,
                    },
                    function (place, status) {
                      if (
                        status ===
                        window.google.maps.places.PlacesServiceStatus.OK
                      ) {
                        handleFormSubmit(
                          place.formatted_address,
                          place.geometry.location.lat(),
                          place.geometry.location.lng()
                        );
                      }
                    }
                  );
                }}
                onInputChange={(event, newInputValue) => {
                  if (newInputValue.length >= MIN_LENGTH_TO_SEARCH) {
                    setIsSearching(true);
                  }
                  setInputValue(newInputValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Enter an address, zip code, city, or country."
                    fullWidth
                  />
                )}
                renderOption={(props, option) => {
                  const matches =
                    option.structured_formatting.main_text_matched_substrings ||
                    [];

                  const parts = parse(
                    option.structured_formatting.main_text,
                    matches.map((match) => [
                      match.offset,
                      match.offset + match.length,
                    ])
                  );

                  return (
                    <li {...props}>
                      <Grid container alignItems="center">
                        <Grid
                          item
                          sx={{
                            width: "calc(100% - 44px)",
                            wordWrap: "break-word",
                          }}
                        >
                          {parts.map((part, index) => (
                            <Box
                              key={index}
                              component="span"
                              sx={{
                                fontWeight: part.highlight ? "bold" : "regular",
                              }}
                            >
                              {part.text}
                            </Box>
                          ))}
                          <Typography variant="body2" color="text.secondary">
                            {option.structured_formatting.secondary_text}
                          </Typography>
                        </Grid>
                      </Grid>
                    </li>
                  );
                }}
              />

              <Hr />
              <div className="">
                <MapPlot
                  map_params={map_params}
                  height={500}
                  id="map-plot"
                  class="mx-auto md:max-w-2xl"
                />
              </div>
              <table className="min-w-full bg-white">
                <thead className="bg-gray-800 text-white">
                  <tr>
                    <th className="w-1/3 text-left py-3 px-4 uppercase font-semibold text-sm">
                      Care Type
                    </th>
                    <th className="w-1/3 text-left py-3 px-4 uppercase font-semibold text-sm">
                      Provider
                    </th>
                    <th className="w-1/3 text-left py-3 px-4 uppercase font-semibold text-sm">
                      Address
                    </th>
                  </tr>
                </thead>
                <tbody className="text-gray-700">
                  {map_params.providers.map((result, index) => (
                    <tr
                      key={result.provider}
                      className="odd:bg-white even:bg-slate-50 align-top text-sm"
                    >
                      <td className="w-1/3 text-left py-3 px-4 ">
                        {result.care_type}
                      </td>
                      <td
                        className="w-1/3 text-left py-3 px-4 providercell"
                        style={{
                          maxHeight: "10px",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "wrap",
                        }}
                        id={`provider-${index}`}
                      >
                        {result.provider}
                      </td>
                      <td className="w-1/3 text-left py-3 px-4">
                        {result.address}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <Hr />
              <div className="text-left text-sm mx-5">
                The results above are the US-based providers closest to your
                address and within 500 miles. Non-US users: Only an IgAN
                Specialist is shown.
                <br /> <br />
                Definitions of the care types included:
                <br /> <br />
                <span className="font-bold">IGAN Specialist</span>: A
                nephrologist who is known to specialize in IgAN, as verified by
                the IGA Nephropathy Foundation.
                <br />
                <span className="font-bold">Clinical Trial</span>: Nearest
                location of a clinical trial site, for trials where an in-person
                visit is likely required.
                <br />
                <span className="font-bold">Glomerular Specialist</span>: A
                physician who likely specializes in a rare glomerular disease,
                but not necessarily IgAN.
                <br />
                <span className="font-bold">Nephrologist</span>: A general
                nephrologist who likely does not specialize in IgAN or other
                rare kidney diseases.
                <br />
                <span className="font-bold">Nephrologist-HMO</span>: A general
                nephrologist who is primarily associated with an HMO network &
                may have more restrictions on what insurances they accept.
                <br />
                <span className="font-bold">Kidney Dietician</span>: Nutrition
                &amp; diet expert specializing in kidney disease management.
                <br />
                <span className="font-bold">CKD Support Group</span>: An
                in-person support group for chronic kidney disease patients;
                virtual support groups,{" "}
                <a
                  href="https://igan.org/support-options/"
                  className="text-sky-500"
                  target="_blank"
                >
                  such as one offered by the IgAN Foundation
                </a>
                , are not indicated here.
                <br />
                <br />
                Contact{" "}
                <a
                  href="mailto:info@tealeafhealth.com"
                  className="text-sky-500"
                  target="_blank"
                >
                  info@tealeafhealth.com
                </a>{" "}
                if you'd like to see other local providers who may not be the
                nearest candidate. US providers further than 500 miles from the
                entered address are not included in the results.
                <br />
                <br />
                This is not medical advice. If you are interested in working
                with one of the results, we suggest you reach out to them
                directly to confirm details.
              </div>
              <Hr />
            </div>
          )}

          {/* MAP LOADING */}
          {isLoadingMap && !isError && <div>Loading map ...</div>}

          {/* ERROR PAGE */}
          {isError && (
            <div>
              <div className="mt-56 mx-96 text-lg font-bold">
                We encountered an error submitting your information. Please
                check your connection to the internet. If you have questions,
                comments, or requests please email info@tealeafhealth.com
              </div>
            </div>
          )}
        </>
      )}
      <br />

      <div className="text-center mb-5">
        <a
          href="https://www.tealeafhealth.com/tl-connect-terms-conditions"
          className="text-sky-500"
          target="_blank"
        >
          Privacy Policy
        </a>
      </div>
    </div>
  );
}
